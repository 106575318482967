.settingTopic .ant-card {
    box-shadow: 0 0 9px #ddd;
}

.settingTopicQuestion span {
    word-break: break-all;
}

.settingTopic .ant-badge-count {
    margin-top: 0;
    margin-left: 10px !important;
    width: 30px !important;
    height: 20px !important;
}

.ant-advanced-search-form .ant-form-item-control-wrapper .ant-input{
    width: 100%;
}

.settingTopicContent div {
    margin: 0;
    padding: 0;
    word-break: break-all;
}

.addNovelList  {
    overflow: hidden;
    margin-top:15px;
}

.addNovelList li {
    list-style: none;
    margin-right:15px;
    width: 120px;
    height:300px;
    float: left;
    margin-bottom: 15px;
}

.addNovelList li .noveList{
    width: 120px;
    text-align: center;
}

.addNovelList li .noveList img{
    display: block;
    width:118px;
    height:157px;
    margin-bottom:5px;
}

.addNovelList li .noveList .novelName {
    width:100%;
    height:60px;
    display: -webkit-box;
    -webkit-box-pack: center;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.recBackground {
    text-align: center;
    font-size: 20px;
    height: 120px;
    line-height: 120px;
    color: #ddd
}

.line-style {
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 335px;
    height: 100px;
    margin-top: 10px;
}

.line-style div {
    width: 70px;
    height: 70px;
    margin: 15px 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #E0E3E3;
    float: left;
}

.vertical-style {
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 115px;
    height: 270px;
    margin-top: 10px;
}

.vertical-style div {
    width: 70px;
    height: 70px;
    margin: 15px 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #E0E3E3;
}

.vertical-line-style {
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 445px;
    height: 200px;
    margin-top: 10px;
}

.vertical-line-style div {
    width: 105px;
    height: 70px;
    float: left;
    margin: 15px 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #E0E3E3;
}

.carousel-style {
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 445px;
    height: 100px;
    margin-top: 10px;
}

.carousel-style div {
    width: 50px;
    height: 70px;
    margin: 15px 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #E0E3E3;
    float: left;
}