.novel-uploader > .ant-upload {
     width: 128px;
     height: 128px;
 }

.chapterMsg div {
    margin-bottom: 20px;
    font-size: 16px;
    vertical-align: top;
}

.chapterMsg span{
    font-weight: bold;
    margin-left: 10px;
}

.addChapter .ant-upload-list .ant-upload-list-item-done{
    color: green;
}