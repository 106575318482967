* {
    font-family: "Microsoft Yahei";
}

.logo {
    height: 32px;
    /*background: rgba(255,255,255,.2);*/
    /*margin: 16px;*/
    width:200px;
    float: left;
}

.logo img {
    width: 100%;
    height: 100%;
}

.exit {
    width: 150px;
    vertical-align: top;
    display: inline-block;
    float: right;
    text-align: right;
}

.AddLivesCard {
    width: 600px;
}

.page-placeholder {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.package-selector {
    float: left;
}

.package-selector .ant-select-selection {
    background: rgb(0, 21, 41);
    color: rgba(255, 255, 255, 0.65);
    border: none;
}