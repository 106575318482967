#root{
    height: 100%;
}

th.column-center,
td.column-center {
    text-align: center !important;
    font-size: 13px;
}

.ant-badge>a {
    color: rgba(0,0,0, 0.65);
    text-decoration: none;
}

.ant-menu-item-selected .ant-badge a {
    color: #1890ff;
    text-decoration: none;
}

.showChapterTitle {
    margin: 20px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.showNovelList {
    margin: 0 50px 0 50px;
}

.showNovelListP {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;

}

.showNovelListP:hover {
    background: rgba(211,224,232, 0.4);
}

.showChapterList {
    width: 100%;
    margin-top: 20px;
}

.novelButton {
    margin: 53px 20px;
    float: right;
}

.novelClearButton {
    margin-top: 53px;
    margin-right: 20px;
    float: right;
}

.bannerButton {
    margin: 53px 20px;
}

.bannerClearButton {
    margin-top: 53px;
    margin-right: 20px;
}

.paramItem {
    margin: 8px 0;
}

.param {
    float: right;
    max-width: 240px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}

.paramHide {
    float: right;
    color: #2392FC;
}

.paramShow {
    float: right;
    color: red;
}

.tagDiv img {
    width: 100%;
}