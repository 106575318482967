.withdrawal_msg {
    width:100%;
    padding-left: 20px;
}

.withdrawal_msg div {
    margin-bottom: 15px;
}

.withdrawal_msg div .withdrawal_msg-title{
    margin-right: 10px;
    font-weight: bold;
}

.withdrawal_table div {
    text-align: center;
    margin-bottom: 15px;
}